import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';

export const catFileMap = {
  'online-shopping': 'general',
  fashion: 'fashion',
  food: 'food',
  'beauty-health': 'health',
  'electronics-interior': 'interior',
  travel: 'travel',
  'digital-content': 'digital',
  'other-services': 'others',
};

export const newsCategoryMap = {
  all: '全て',
  event_info: 'イベント情報',
  merchant_info: '加盟店情報',
  campaign_info: 'キャンペーン情報',
  csr_info: 'CSR情報',
  press: 'プレスリリース',
  ir: 'IR情報',
  media: 'メディア掲載情報',
  others: 'その他',
};

// TODO
export const titlePathnameMap = {
  '/': 'トップ',
  '/apple/': 'ペイディあと払いプランApple専用',
  '/payments/': '使い方',
  '/shop/': '使えるお店',
  '/plus/': 'ペイディプラス',
  '/campaign/': 'キャンペーン',
  '/column/': 'コラム',
  '/company/': '会社プロフィール',
  '/merchant/': '法人のお客様',
  '/security/': '安全への取り組み',
  '/landing/': '総合トップ',
  '/landing_ekyc/': '総合トップ',
  '/faq/': 'よくあるご質問',
  '/merchant/contact_us/': 'ご相談のお申し込み',
  '/merchant/industry/goods/': '物販',
  '/merchant/industry/digital/': 'デジタルコンテンツ',
  '/merchant/industry/travel/': 'トラベル・アクティビティ',
  '/merchant/industry/subscription/': 'サブスクリプション',
  '/merchant/application/': '導入の流れ',
  '/merchant/case_study/': '導入事例',
  '/merchant/materials/white_paper/': 'お役立ち情報',
  '/merchant/materials/sales/': '資料請求',
  '/merchant/materials/faq/': 'よくあるご質問',
  '/media_center/': 'メディアセンタートップ',
  '/media_center/article/': '論文',
  '/media_center/fun/': 'Paidyファン',
  '/media_center/press/': 'Paidyプレス',
  '/media_center/work_life/': 'Paidyワーク&ライフ',
  '/media_center/work_life/hr/': 'HRブログ',
  '/media_center/work_life/engineering/': 'エンジニアブログ',
  '/dream/': 'dream',
  '/service': 'service',
  '/service/': 'service',
  '/news/': 'news',
  '/hr-blog': 'HRブログ | 株式会社Paidy',
  '/hr-blog/': 'HRブログ | 株式会社Paidy',
  '/press_kit/': 'press_kit',
  '/about/': 'about',
  '/about': 'about',
  '/recruit/': 'recruit',
  '/recruit': 'recruit',
};

export const CONTENTFUL_DEFAULT_SPACE = 'i7it8bepy72j';
export const CONTENTFUL_MYPAIDY_SPACE = '9o41oqhppz7q';
export const CONTENTFUL_PATH =
  process.env.GATSBY_ENVIRONMENT === 'production'
    ? '/contentful-data/'
    : '/preview/';
export const NUMBER_OF_PICKS = 1;
export const STEP = 3;
export const MAX_NUMBER_OF_FIRST_CAROUSEL = 8;
export const MAX_NUMBER_OF_SECOND_CAROUSEL = 8;

export const CONTACT_US_ENDPOINT = process.env.GATSBY_CONTACT_US_ENDPOINT;

export const PAIDY_HOST =
  process.env.GATSBY_ENVIRONMENT === 'production'
    ? 'https://paidy.com'
    : 'https://paidy-staging.com';

export const PAIDY_DOWNLOAD_HOST =
  process.env.GATSBY_ENVIRONMENT === 'production'
    ? 'https://download.paidy.com'
    : 'https://download.paidy-staging.com';

export const APPLE_LINKS = {
  WEBSITE: 'https://www.apple.com/jp/shop',
  STORE: 'https://www.apple.com/jp/retail/',
};

export const HOME_FAQS = [
  {
    question: 'ペイディを利用する際に、必要なものはありますか？',
    answer:
      'お客様の携帯電話番号とメールアドレスが必要です。ただし、お客様のお申込み内容により、当社指定の情報をご入力いただきます。',
  },
  {
    question: '３回あと払いとはどのようなサービスですか。',
    answer: (
      <>
        <span>
          分割手数料無料*で等額決済金額を3回に分けてお支払いできるサービスです。決済金額が3,000円以上のお買い物にご利用いただけます。
        </span>
        <br />
        <br />
        <span>
          初回お支払いはご利用翌月の10日、2回目は翌々月10日、最終お支払いは3ヵ月後の10日になります。なお、口座振替ご登録の方の請求金額は、毎月請求月の12日（金融機関休業日の場合は翌営業日）に自動で引き落とされます。
        </span>
        <br />
        <br />
        <span>
          コンビニでお支払いいただく場合には別途支払い手数料がかかります。詳細は「
          <OutboundLink
            href="https://faq.paidy.com/%E3%82%B3%E3%83%B3%E3%83%93%E3%83%8B%E3%81%A7%E3%81%AE%E6%94%AF%E6%89%95%E3%81%84%E6%96%B9%E6%B3%95%E3%81%8C%E3%82%8F%E3%81%8B%E3%82%89%E3%81%AA%E3%81%84"
            rel="noopener noreferrer"
            target="_blank"
          >
            コンビニ払いについて
          </OutboundLink>
          」をご覧ください。銀行振込をご利用の場合は振込手数料の実費をお客様にご負担いただきます。口座振替の場合は、支払い手数料はかかりません。
        </span>
        <br />
        <br />
        <span>
          なお、超あと払いをご利用頂いた決済を３回あと払いに変更することはできません。また３回あと払いに変更済みのお支払い金額に対して、さらに３回あと払いをご利用いただくこともできません。
        </span>
        <br />
        <br />
        <small>*分割手数料無料は、口座振替、銀行振込のみ</small>
      </>
    ),
  },
  {
    question: 'どこでもペイディとは何ですか？',
    answer:
      'Visaのオンライン加盟店でペイディをご利用いただけるサービスです。通常のペイディと同様に、翌月あと払いや３回あと払いをご利用いただけます。',
  },
];

export const HOME_TOP_BANNERS = [
  {
    img: require('../images/hero-top-1.png'),
    to: '/payments/',
  },
  {
    img: require('../images/hero-top-2.png'),
    to: '/apple/',
  },
  {
    img: require('../images/hero-top-3.png'),
    to: '/campaign_202109/',
  },
];

// TODO: Waiting for full
export const SITE_METADATA = {
  payments: {
    title: 'あと払いペイディ｜使い方｜翌月後払いのPaidy',
    description:
      '「あと払いペイディの使い方」では、ペイディの詳しい使い方を紹介。メアドと携帯番号だけでお買い物可能で、お支払いはまとめて翌月10日まででOK。銀行振込・口座振替・コンビニ払いが利用可能。アプリからペイディプラスへ無料アップグレードすると、分割手数料無料の３回あと払いや、Visaのオンライン加盟店でもペイディが使える「どこでもペイディ」が利用可能になります。',
  },
  plus: {
    title: 'あと払いペイディ｜ペイディプラス｜翌月後払いのPaidy',
    description:
      'あと払いペイディ｜本人確認でもっと便利に\n\nペイディでお買い物をもっと自由にあなたらしく。本人確認をすることで、より便利に安心してお買い物をお楽しみいただけます。詳細はご利用ガイドをご覧ください。',
  },
  shop: {
    title: 'あと払いペイディ｜使えるお店・ウェブサイト｜翌月後払いのPaidy',
    description:
      'ペイディが使えるお店をご紹介します。AmazonやQoo10、DMMなど、いつもご利用のお店でも、メアドと携帯番号だけでお買い物可能。お支払いはまとめて翌月10日まででOK。銀行振込・口座振替・コンビニ払いが利用可能。アプリからペイディプラスへ無料アップグレードすると、分割手数料無料の３回あと払いや、Visaのオンライン加盟店でもペイディが使える「どこでもペイディ」が利用可能になります。',
  },
  campaign: {
    title: 'あと払いペイディのお得なキャンペーン情報｜翌月後払いのPaidy',
    description:
      'あと払いペイディのお得なキャンペーン情報を紹介。ペイディならメアドと携帯番号だけでお買い物可能で、お支払いはまとめて翌月10日まででOK。銀行振込・口座振替・コンビニ払いが利用可能。アプリからペイディプラスへ無料アップグレードすると、分割手数料無料の３回あと払いや、Visaのオンライン加盟店でもペイディが使える「どこでもペイディ」が利用可能になります。',
  },
  apple: {
    title:
      'あと払いペイディ｜ペイディあと払いプランApple専用｜翌月後払いのPaidy',
    description:
      'ペイディあと払いプランApple専用は、Appleでの購入専用の特別なご利用可能額をご用意。一度登録を完了するだけで、Appleのウェブサイト、または お近くのApple Storeで何度でもご利用可能です。お申し込みは簡単、最短5分で完了します。iPhoneやMac、iPad、Apple Watch、AppleCare、アクセサリなど、Appleで製品を最も賢く購入する方法のひとつです。',
  },
  security: {
    title: 'あと払いペイディ｜安全への取り組み｜翌月後払いのPaidy',
    description:
      'あと払いペイディの安全への取り組みをご紹介。ペイディでは、お客様に安心・安全にお買い物を楽しんでいただくために、様々な取り組みをしています。認証コードによる二段階認証・本人確認の徹底・24時間365日体制で監視。今後もセキュリティ対策を強化していきます。',
  },
  company: {
    title: '会社プロフィール｜株式会社Paidy',
    description:
      '株式会社Paidyのマネジメントチームや会社情報、出資企業、IR/投資家情報など、会社プロフィールを掲載しています。',
  },
  press: {
    title: 'プレスリリース｜株式会社Paidy',
    description: '株式会社Paidyのプレスリリースを掲載しています。',
  },
  merchant: {
    title: 'あと払いペイディ｜法人のお客様｜株式会社Paidy',
    description:
      'クレジットカードのいらないあと払い決済「ペイディ」なら、ユーザーのニーズに合わせた決済でカゴ落ちを防ぎ、売上アップが見込めます。',
  },
  merchantApplication: {
    title: 'あと払いペイディ｜導入の流れ｜株式会社Paidy',
    description:
      'あと払いペイディの導入の流れをご紹介。クレジットカードのいらないあと払い決済「ペイディ」なら、ユーザーのニーズに合わせた決済でカゴ落ちを防ぎ、売上アップが見込めます。',
  },
  merchantContact: {
    title: 'あと払いペイディ｜導入のご相談｜株式会社Paidy',
    description:
      'あと払いペイディの導入のご相談はこちらから。クレジットカードのいらないあと払い決済「ペイディ」なら、ユーザーのニーズに合わせた決済でカゴ落ちを防ぎ、売上アップが見込めます。',
  },
  campaign202109: {
    title: 'あと払いペイディ｜TVCM｜翌月後払いのPaidy',
    description:
      '[Alexandros] 川上洋平さん出演のペイディTVCMをご紹介。WEB限定のロングバージョンや、CMのメイキング動画、川上洋平さんのインタビューなど、特別なコンテンツをお楽しみください。',
  },
  top: {
    title: 'トップ｜株式会社Paidy',
    description:
      'あと払いペイディは、クレジットカードや事前登録不要。メアドと携帯番号だけでお買い物可能で、お支払いはまとめて翌月10日まででOK。銀行振込・口座振替・コンビニ払いが利用可能。アプリからペイディプラスへ無料アップグレードすると、分割手数料無料の３回あと払いや、Visaのオンライン加盟店でもペイディが使える「どこでもペイディ」が利用可能になります。',
  },
  column: {
    title: 'あと払いペイディ｜コラム｜翌月後払いのPaidy',
    description:
      'あと払いペイディの機能や使い方をご紹介。ペイディならメアドと携帯番号だけでお買い物可能で、お支払いはまとめて翌月10日まででOK。銀行振込・口座振替・コンビニ払いが利用可能。アプリからペイディプラスへ無料アップグレードすると、分割手数料無料の３回あと払いや、Visaのオンライン加盟店でもペイディが使える「どこでもペイディ」が利用可能になります。',
  },
  about: {
    title: '会社概要｜株式会社Paidy',
    description: '会社概要｜株式会社Paidy',
  },
  news: {
    title: 'ニュースルーム｜株式会社Paidy',
    description: 'ニュースルーム｜株式会社Paidy',
  },
  pressKit: {
    title: 'プレスキット｜株式会社Paidy',
    description: 'プレスキット｜株式会社Paidy',
  },
  recruit: {
    title: '採用情報｜株式会社Paidy',
    description: '採用情報｜株式会社Paidy',
  },
  jobPost: {
    title: '募集中のポジション｜株式会社Paidy',
    description: '募集中のポジション｜株式会社Paidy',
  },
  service: {
    title: '事業内容｜株式会社Paidy',
    description: '事業内容｜株式会社Paidy',
  },
  hrBlog: {
    title: 'HRブログ｜株式会社Paidy',
    description: 'HRブログ｜株式会社Paidy',
  },
  dream: {
    title: 'Paidyが目指す未来｜株式会社Paidy',
    description: 'Paidyが目指す未来｜株式会社Paidy',
  },
};

export const LANGUAGE = {
  EN: 'EN',
  JA: 'JA',
};

export const RECRUIT_PAGE_STATIC_DATA = {
  [LANGUAGE.JA]: {
    bannerText: '桁違いの創造的イノベーションを',
    culture: {
      primaryText: 'お買いものに\n「めんどくさい」\nはいらない。',
      secondaryText: '夢に自信を持ち、心に余裕を持てる世界',
      topRight: [
        {
          title: '勝ちにこだわる',
          content: [
            '私たちは、常に期待値を超える。',
            '人をスピードで驚かす。',
            'リスクを恐れない。',
          ],
        },
        {
          title: '結果を出す',
          content: [
            '私たちは、目的・やり方・期限にコミットする。',
            '結果のためのアクションにこだわる。',
            '必要なら対立・衝突も恐れない。',
          ],
        },
        {
          title: '大切なピースになる',
          content: [
            '私たちは、替えの効かない貢献をする。',
            '皆が言語と文化の架け橋になる。',
            'スタンダードを上げ続ける。',
          ],
        },
      ],
      btmLeft: {
        titleP1: 'Paidyは他に類を見ない、',
        titleP2: '30か国以上',
        titleP3: 'の多国籍なメンバーが集う会社です。',
        column1: [
          'アルゼンチン',
          'オーストラリア',
          'ベラルーシ',
          'ブラジル',
          'カナダ',
          '中国',
          'コロンビア',
          'コスタリカ',
          'フランス',
          'ドイツ',
          'インド',
          'インドネシア',
          'イスラエル',
          'イタリア',
          '日本',
        ],
        column2: [
          '韓国',
          'メキシコ',
          'モロッコ',
          'ニュージーランド',
          'フィリピン',
          'ポーランド',
          'ポルトガル',
          'ロシア',
          'スペイン',
          '台湾',
          'タイ',
          'イギリス',
          'ウクライナ',
          '米国',
          'ウズベキスタン',
        ],
      },
      keywords: {
        title: 'ペイディのカルチャーを表す10のキーワード',
        content: [
          {
            text: '未来を見据える',
            image: require('../images/keyword-visionary.svg'),
          },
          {
            text: '期待を裏切らない',
            image: require('../images/keyword-confidence.svg'),
          },
          {
            text: '真摯な姿勢',
            image: require('../images/keyword-integrity.svg'),
          },
          {
            text: 'チャレンジ精神',
            image: require('../images/keyword-courage.svg'),
          },
          {
            text: '正面からリスクに挑む',
            image: require('../images/keyword-risk-take.svg'),
          },
          {
            text: '本気でやる',
            image: require('../images/keyword-enthusiastic.svg'),
          },
          {
            text: '一気に解決する',
            image: require('../images/keyword-dynamic-problem.svg'),
          },
          {
            text: '思いやりを持つ',
            image: require('../images/keyword-empathy.svg'),
          },
          {
            text: 'すべてを受け入れる',
            image: require('../images/keyword-inclusivity.svg'),
          },
          {
            text: '頼りになる',
            image: require('../images/keyword-trust.svg'),
          },
        ],
      },
    },
    message: {
      founder: {
        message:
          '「Paidyは、社会に新しい価値を生み出しているイノベーティブな会社です。BNPLは、日本においてはまだ始まったばかり。今後も革新的なお買い物サービスを届け続け、誰も見たことのない成長ストーリーを描いていきます」',
        sign1: 'ラッセル・カマー',
        sign2: '代表取締役会長',
      },
      ceo: {
        message:
          '「Paidyには、社員が日々成長できる環境があります。だからこそ、私たちのビジネス、プロダクト、サービスも成長し続けているのです。Paidyが世界中の才能あふれる方々を魅了していることを、とても誇りに思います。ぜひあなたも、『ドリームチーム』に参加してください！」',
        sign1: '杉江 陸',
        sign2: '代表取締役社長兼CEO',
      },
    },
    job: {
      title: '募集中のポジション',
      seeMore: 'もっと詳しく',
    },
    hr: {
      title: 'HRからのお知らせ',
      seeMore: 'もっと見る',
      continueReadingLabel: '記事を読む',
    },
  },

  [LANGUAGE.EN]: {
    bannerText: 'Drive powerful innovations with us',
    culture: {
      primaryText: 'Take the hassle out of shopping',
      secondaryText: 'Provide everyone with room to dream',
      topRight: [
        {
          title: 'Be a Winner',
          content: [
            'Beat expectations',
            'Display surprising speed',
            'Embrace risk',
          ],
        },
        {
          title: 'Own it and deliver',
          content: [
            'Commit to what, when and how to deliver',
            'Own the actions to deliver',
            'Embrace conflict when needed to deliver results',
          ],
        },
        {
          title: 'Play an integral role',
          content: [
            'Make an irreplaceable contribution to our business',
            'Embrace and bridge differences in language and culture',
            'Raise the bar',
          ],
        },
      ],
      btmLeft: {
        titleP1: 'With members coming from more than  ',
        titleP2: '30 nationalities',
        titleP3: ' - Paidy is a one of kind company based in Tokyo!',
        column1: [
          'Argentina',
          'Australia',
          'Belarus',
          'Brazil',
          'Canada',
          'China',
          'Columbia',
          'Costa Rica',
          'France',
          'Germany',
          'India',
          'Indonesia',
          'Israel',
          'Italy',
          'Japan',
        ],
        column2: [
          'Korea',
          'Mexico',
          'Morocco',
          'New Zealand',
          'Philippines',
          'Poland',
          'Portugal',
          'Russia',
          'Spain',
          'Taiwan',
          'Thailand',
          'England',
          'Ukraine',
          'USA',
          'Uzbekistan',
        ],
      },
      keywords: {
        title: 'Words that represent Paidy Culture',
        content: [
          {
            text: 'Visionary',
            image: require('../images/keyword-visionary.svg'),
          },
          {
            text: 'Confidence',
            image: require('../images/keyword-confidence.svg'),
          },
          {
            text: 'Integrity',
            image: require('../images/keyword-integrity.svg'),
          },
          {
            text: 'Courage',
            image: require('../images/keyword-courage.svg'),
          },
          {
            text: 'Risk taking',
            image: require('../images/keyword-risk-take.svg'),
          },
          {
            text: 'Enthusiasm',
            image: require('../images/keyword-enthusiastic.svg'),
          },
          {
            text: 'Problem solving',
            image: require('../images/keyword-dynamic-problem.svg'),
          },
          {
            text: 'Empathy',
            image: require('../images/keyword-empathy.svg'),
          },
          {
            text: 'Inclusivity',
            image: require('../images/keyword-inclusivity.svg'),
          },
          {
            text: 'Trust',
            image: require('../images/keyword-trust.svg'),
          },
        ],
      },
    },
    message: {
      founder: {
        preMessage: 'Be magic!',
        message:
          ' Paidy is super innovative company, having created new services, and continue to create much more soon! BNPL is just our beginning. We will realize amazing shopping experience, mesmerize consumers and win big.',
        sign1: 'Russell Cummer',
        sign2: '- Founder & Executive Chairman -',
      },
      ceo: {
        preMessage: 'It is amazing',
        message:
          ' that we could attract highly talented people from all corners of the world, to realize our promises to our customers. Why not join in our dream team! People in Paidy are living in evolution. We are letting our employees evolve themselves in our workplaces. By doing so, we are evolving our business, products and services to change the world.',
        sign1: 'Riku Sugie',
        sign2: '- President & CEO -',
      },
    },
    job: {
      title: 'Open positions',
      seeMore: 'See more',
    },
    hr: {
      title: 'HR Announcements',
      seeMore: 'See more',
      continueReadingLabel: 'Continue reading',
    },
  },
};
