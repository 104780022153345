const NAMESPACE = '@@paidy.com/merchant';

//Async
export const GET_PAGE_GOODS = `${NAMESPACE}/GET_PAGE_GOODS`;
export const GET_PAGE_DIGITAL = `${NAMESPACE}/GET_PAGE_DIGITAL`;
export const GET_PAGE_TRAVEL = `${NAMESPACE}/GET_PAGE_TRAVEL`;
export const GET_PAGE_SUBSCRIPTION = `${NAMESPACE}/GET_PAGE_SUBSCRIPTION`;
export const GET_PAGE_TOP = `${NAMESPACE}/GET_PAGE_TOP`;

//Sync
export const SET_PAGE_GOODS = `${NAMESPACE}/SET_PAGE_GOODS`;
export const SET_PAGE_DIGITAL = `${NAMESPACE}/SET_PAGE_DIGITAL`;
export const SET_PAGE_TRAVEL = `${NAMESPACE}/SET_PAGE_TRAVEL`;
export const SET_PAGE_SUBSCRIPTION = `${NAMESPACE}/SET_PAGE_SUBSCRIPTION`;
export const SET_PAGE_TOP = `${NAMESPACE}/SET_PAGE_TOP`;
