import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1048,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#101825',
    },
    secondary: {
      main: '#EEF0F2',
    },
    error: {
      light: '#ffd1ce',
      main: '#f44336',
    },
    warning: {
      light: '#ffebb0',
      main: '#ffc107',
    },
    success: {
      main: '#4caf50',
      light: '#ddffdf',
    },
  },
  typography: {
    fontFamily: ['Noto Sans JP', 'Helvetica', 'sans-serif'],
    color: '#101825',
  },
});

theme.overrides = {
  ...theme.overrides,
  MuiTypography: {
    h1: {
      fontWeight: 700,
      fontSize: 44,
      lineHeight: '60px',
      fontFamily: theme.typography.fontFamily,
    },
    h2: {
      fontWeight: 700,
      fontSize: 36,
      lineHeight: '50px',
      fontFamily: theme.typography.fontFamily,
    },
    h3: {
      fontWeight: 700,
      fontSize: 28,
      lineHeight: '40px',
      fontFamily: theme.typography.fontFamily,
    },
    h4: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32px',
      fontFamily: theme.typography.fontFamily,
    },
    h5: {
      fontWeight: 700,
      fontSize: 21,
      lineHeight: '32px',
      fontFamily: theme.typography.fontFamily,
    },
    h6: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '32px',
      fontFamily: theme.typography.fontFamily,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      fontFamily: theme.typography.fontFamily,
    },
    body2: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: '34px',
      fontFamily: theme.typography.fontFamily,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      fontFamily: theme.typography.fontFamily,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '26px',
      fontFamily: theme.typography.fontFamily,
    },
  },
  MuiButton: {
    containedPrimary: {
      height: '56px',
      background: 'linear-gradient(135deg, #2196F3 0%, #823CD7 100%)',
      font: `bold 16px/56px ${theme.typography.fontFamily}`,
      color: '#fff',
      padding: '0 32px',
      borderRadius: '28px',
      display: 'block',
      cursor: 'pointer',
      boxShadow: 'none',
      '& > $label': {
        textAlign: 'center',
        zIndex: 2,
        position: 'relative',
      },
    },
    outlinedPrimary: {
      backgroundColor: '#FFFFFF',
      border: '2px solid #2A2E32',
      boxSizing: 'border-box',
      borderRadius: '28px',
      color: '#101825',
      height: '56px',
      font: `bold 16px/52px ${theme.typography.fontFamily}`,
      padding: '0 32px',
      display: 'inline-block',
      cursor: 'pointer',
      boxShadow: 'none',
      '&:hover': {
        border: '2px solid #2A2E32',
        backgroundColor: '#FFFFFF',
      },
    },
    outlinedSecondary: {
      border: 'none',
      boxSizing: 'border-box',
      borderRadius: '28px',
      cursor: 'pointer',
      boxShadow: 'none',
      height: '56px',
      backgroundColor: 'transparent',
      padding: 0,
      marging: 0,
      '& > $label': {
        textAlign: 'center',
        display: 'block',
        zIndex: 2,
        position: 'relative',
        backgroundColor: '#101825',
        height: '54px',
        margin: '1px',
        padding: '0 48px',
        color: '#fff',
        borderRadius: '28px',
        font: `bold 16px/52px ${theme.typography.fontFamily}`,
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        height: '56px',
        width: '100%',
        background: 'linear-gradient(135deg, #2196F3 0%, #823CD7 100%)',
        borderRadius: '28px',
        top: 0,
        left: 0,
        right: 0,
        opacity: 1,
        zIndex: 1,
        filter: 'blur(0)',
        webkitFilter: 'blur(0)',
      },
      '&:hover': {
        border: 'none',
      },
    },
    outlinedSizeSmall: {
      height: '40px',
      padding: 0,
      marging: 0,
      lineHeight: '38px',
      '& > $label': {
        textAlign: 'center',
        height: '38px',
        lineHeight: '38px',
        font: `bold 14px/24px ${theme.typography.fontFamily}`,
      },
      '&::before': {
        height: '40px',
      },
    },
    containedSecondary: {
      border: 'none',
      boxSizing: 'border-box',
      borderRadius: '28px',
      cursor: 'pointer',
      boxShadow: 'none',
      height: '56px',
      backgroundColor: '#fff',
      padding: '0 48px',
      marging: 0,
      minWidth: ' 270px',
      font: `bold 16px/52px ${theme.typography.fontFamily}`,
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    textPrimary: {
      height: '56px',
      background: 'linear-gradient(90deg, #E5258C 0%, #823CD7 100%)',
      font: `bold 16px/56px ${theme.typography.fontFamily}`,
      color: '#fff',
      padding: '0 32px',
      borderRadius: '28px',
      display: 'block',
      cursor: 'pointer',
      boxShadow: 'none',
      '& > $label': {
        textAlign: 'center',
        zIndex: 2,
        position: 'relative',
      },
    },
  },
  MuiLink: {
    root: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      fontFamily: theme.typography.fontFamily,
    },
  },
  MuiTextField: {
    root: {
      marginBottom: '34px',
    },
  },
  MuiFormHelperText: {
    root: {
      '&$error': {
        font: `400 13px/18px ${theme.typography.fontFamily} !important`,
        marginLeft: 0,
      },
    },
  },
  MuiFilledInput: {
    root: {
      marginTop: '44px',
    },
    // TODO remove !important after get rid of form.scss
    input: {
      backgroundColor: '#F2F2F2 !important',
      borderRadius: '0 !important',
      padding: '0 40px !important',
      height: '48px !important',
      lineHeight: '48px !important',
      border: 'none !important',
      font: `300 14px/24px ${theme.typography.fontFamily} !important`,
    },
  },
  MuiOutlinedInput: {
    input: {
      border: 'none !important',
      font: `300 14px/24px ${theme.typography.fontFamily} !important`,
    },
    notchedOutline: {
      borderColor: '#EBE6E2',
    },
    root: {
      '&$focused': {
        borderWidth: '1px',
        '& $notchedOutline': {
          borderWidth: '1px',
        },
      },
    },
  },
  MuiInputLabel: {
    root: {
      color: `${theme.palette.primary.main} !important`,
      transform: 'none !important',
      font: `400 16px/26px ${theme.typography.fontFamily}`,
    },
    required: {
      font: `700 16px/26px ${theme.typography.fontFamily} !important`,
      color: theme.palette.primary.main,
    },
    filled: {
      color: `${theme.palette.primary.main} !important`,
      transform: 'none !important',
      font: `400 16px/26px ${theme.typography.fontFamily}`,
    },
    asterisk: {
      fontSize: 0,
      '&:before': {
        font: `300 16px/26px ${theme.typography.fontFamily}`,
        content: '" ※"',
        color: `${theme.palette.primary.main} !important`,
      },
    },
  },
  MuiCircularProgress: {
    circle: {
      color: '#823CD7',
    },
  },
  MuiChip: {
    root: {
      height: '36px',
      padding: '0 40px',
      font: `400 14px/24px ${theme.typography.fontFamily}!important`,
      borderRadius: '20px',
      marginBottom: '16px',
      marginRight: '16px',
      backgroundColor: '#EBE6E2',
    },
    clickableColorSecondary: {
      '&:hover, &:focus': {
        backgroundColor: '#EBE6E2',
      },
    },
  },
  MuiToggleButton: {
    root: {
      border: 'none !important',
      boxSizing: 'border-box',
      borderRadius: '28px !important',
      cursor: 'pointer',
      boxShadow: 'none',
      color: `#999495 !important`,
      height: '56px',
      backgroundColor: '#fff',
      padding: '0 48px',
      margin: 0,
      minWidth: ' 270px',
      font: `bold 16px/52px ${theme.typography.fontFamily} !important`,
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    selected: {
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: '#fff !important',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), -10px 15px 20px rgba(81, 37, 37, 0.12)',
    },
  },
  MuiFormGroup: {
    root: {
      flexDirection: 'row',
    },
  },
  MuiFormControlLabel: {
    root: {
      width: '33%',
      marginRight: 11,
      marginTop: 10,
    },
    label: {
      font: `400 16px/26px ${theme.typography.fontFamily} !important`,
    },
  },
  MuiCheckbox: {
    checked: {
      '& + span': {
        color: '#0064d2',
      },
    },
    colorPrimary: {
      color: '#111926',
      '&$checked': {
        color: '#2296f3',
      },
    },
  },
  MuiTabs: {
    root: {
      borderBottom: '1px solid #CFC4C5',
    },
    indicator: {
      background: 'linear-gradient(59.24deg, #E5258C 9.27%, #863BD4 81.8%)',
    },
  },
  MuiTab: {
    root: {
      minWidth: 'auto !important',
      paddingLeft: 0,
      marginRight: 20,
      color: '#101825 !important',
      fontSize: 16,
      background: 'transparent',
      position: 'relative',
      paddingBottom: '11px',
      '&$selected': {
        fontWeight: 'bold',
      },
    },
  },
};

export default theme;
