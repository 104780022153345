//Local
import {
  SET_PAGE_DIGITAL,
  SET_PAGE_GOODS,
  SET_PAGE_TRAVEL,
  SET_PAGE_SUBSCRIPTION,
  SET_PAGE_TOP,
} from './types';

const initialState = {
  goodsPage: {},
  digitalPage: {},
  travelPage: {},
  subscriptionPage: {},
  topPage: {},
};

const merchantReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PAGE_GOODS:
      return { ...state, goodsPage: payload };

    case SET_PAGE_DIGITAL:
      return { ...state, digitalPage: payload };

    case SET_PAGE_TRAVEL:
      return { ...state, travelPage: payload };

    case SET_PAGE_SUBSCRIPTION:
      return { ...state, subscriptionPage: payload };

    case SET_PAGE_TOP:
      return { ...state, topPage: payload };

    default:
      return state;
  }
};

export default merchantReducer;
